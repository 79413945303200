import { useContext, useEffect, useMemo, useRef } from 'react';

import {
  LOT_JSON_CUSTOM_PRICE1,
  LOT_JSON_KIND,
  LOT_JSON_NUMBER,
  LOT_JSON_PRICE_INCLUDING_TAX,
  LOT_JSON_PRICE_REDUCED_VAT,
  LOT_JSON_PROGRAM_REF,
  LOT_JSON_STATUS,
  LOT_STATUS_FREE,
} from 'settings/lots';

import type { LotExport } from 'api/viOffresAPI/apiTypes/LotType';
import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';
import type { TaxesById } from 'api/viOffresAPI/apiTypes/Taxonomies';

import programLotContext from 'modules/App/Contexts/programLotContext';
import SettingsContext from 'modules/App/Contexts/SettingsContext';
import TaxonomiesContext from 'modules/App/Contexts/TaxonomiesContext';
import userContext from 'modules/App/Contexts/userContext';

import { filterNullable } from 'services/array';
import { getLotPrice } from 'services/lots';

import { useSWROffresImmutable } from 'api/offresAPI/useSWROffres';

function getUrl(programRef: string, attributionToken?: string | false) {
  const baseUrl = `/programmes/${programRef}`;
  return attributionToken ? `${baseUrl}?listeAttribution=${attributionToken}` : baseUrl;
}
function getPriceRange(prices: any[]) {
  const pricesAsNumbers = prices.reduce<number[]>((array, price) => {
    const priceNumber = parseInt(price, 10);
    if (!Number.isNaN(priceNumber)) {
      return [...array, priceNumber];
    }
    return array;
  }, []);
  if (pricesAsNumbers.length) {
    // We check the length to avoid Math.min & Math.max returning Infinity when they're given no argument
    return [Math.min(...pricesAsNumbers), Math.max(...pricesAsNumbers)].join('-');
  }
  return '-';
}

function completeProgramDetail(
  program: ProgramTypeV2 | undefined,
  lotsExport: LotExport[],
  taxesById: TaxesById
): ProgramTypeV2 | undefined {
  if (!program || !lotsExport?.length) {
    return program;
  }
  return {
    ...program,
    // Count the number of Lots with "free" status
    nbLotsDisponibles: lotsExport.filter(lot => lot[LOT_JSON_STATUS] === LOT_STATUS_FREE).length,
    //
    aPartirDe: Math.min(
      ...filterNullable(lotsExport.map(lot => getLotPrice(lot, taxesById)))
    ).toString(10),
    informationsFiscalesEtFinancieres: {
      ...program.informationsFiscalesEtFinancieres,
      prixHorsMobilier: getPriceRange(lotsExport.map(lot => lot[LOT_JSON_CUSTOM_PRICE1])),
      prixTVAReduite: getPriceRange(lotsExport.map(lot => lot[LOT_JSON_PRICE_REDUCED_VAT])),
      prixTVANormal: getPriceRange(lotsExport.map(lot => lot[LOT_JSON_PRICE_INCLUDING_TAX])),
    },
    // Remove lots that are not in the export, which means that they have been excluded because of attribution lists
    lots: Object.fromEntries(
      Object.entries(program.lots).reduce((acc, [typo, { lots }]) => {
        const filteredLots = lots.filter(lot =>
          lotsExport.some(lotExport => lotExport[LOT_JSON_NUMBER] === lot.reference)
        );
        if (filteredLots.length) {
          return [...acc, [typo, { lots: filteredLots }]];
        }
        return acc;
      }, [])
    ),
    // Count the number of Lots for each Kind
    repartitionsLots: JSON.stringify(
      lotsExport.reduce(
        (map, lot) => map.set(lot[LOT_JSON_KIND], (map.get(lot[LOT_JSON_KIND]) ?? 0) + 1),
        new Map<string, number>()
      ),
      // JSON.stringify doesn't know how to handle Maps natively, so we need to provide him a replacer function
      (_, value) => {
        if (value instanceof Map) {
          return Object.fromEntries(value.entries());
        }
        return value;
      }
    ),
  };
}

/**
 * Fetch the desired detailed program base on its Program Reference and if the Attribution Lists switch
 * is on, then replace some values by ones calculated with Lots data from the Export file
 */
export default function useAttributionProgram(programRef: string | undefined, enable = true) {
  const { settings } = useContext(SettingsContext);
  const isAttributionSwitchedOn = !!settings.cdo?.liste_attribution;
  const { userCrm } = useContext(userContext);
  const { taxesById } = useContext(TaxonomiesContext);

  const laggyProgramRef = useRef<ProgramTypeV2>();
  const { data: programSwr, isLoading: isProgramLoading, error } = useSWROffresImmutable<
    ProgramTypeV2
  >(
    {
      url:
        programRef && enable
          ? getUrl(programRef, !isAttributionSwitchedOn && userCrm?.extension_VI3P_ListeAttribution)
          : undefined,
    },
    { keepPreviousData: true }
  );

  useEffect(() => {
    if (programSwr !== undefined) {
      laggyProgramRef.current = programSwr;
    }
  }, [programSwr]);
  const program = laggyProgramRef.current ?? programSwr;

  const { lots = [], isLotsLoading } = useContext(programLotContext);
  const programLots = useMemo(() => lots.filter(lot => lot[LOT_JSON_PROGRAM_REF] === programRef), [
    lots,
  ]);

  return useMemo(
    () => ({
      program: isAttributionSwitchedOn
        ? completeProgramDetail(program, programLots, taxesById)
        : program,
      isLoading: isAttributionSwitchedOn ? isProgramLoading || isLotsLoading : isProgramLoading,
      error,
    }),
    [isAttributionSwitchedOn, program, programLots, isProgramLoading, isLotsLoading, error]
  );
}
