import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import RadioGroup from 'commonUi/RadioButton/RadioGroup';
import RadioLabel from 'commonUi/RadioButton/RadioLabel';
import TextField from 'commonUi/TextField/TextField';
import Button from 'commonUi/Button/Button';
import PhoneField from 'commonUi/PhoneField/PhoneField';
import SelectField from 'commonUi/SelectField/SelectField';
import DateField from 'commonUi/DateField/DateField';

import {
  FIELDSET_CO_PROSPECT,
  FIELDSET_PROSPECT,
  FIELD_CO_PROSPECT,
  FIELD_PROSPECT_ADDRESS,
  FIELD_PROSPECT_BIRTH_DATE,
  FIELD_PROSPECT_BIRTH_PLACE,
  FIELD_PROSPECT_CITY,
  FIELD_PROSPECT_CIVILITY,
  FIELD_PROSPECT_COUNTRY,
  FIELD_PROSPECT_EMAIL,
  FIELD_PROSPECT_FAMILY_STATUS,
  FIELD_PROSPECT_FIRSTNAME,
  FIELD_PROSPECT_LASTNAME,
  FIELD_PROSPECT_NATIONALITY,
  FIELD_PROSPECT_PHONE,
  FIELD_PROSPECT_SITUATION,
  FIELD_PROSPECT_ZIPCODE,
} from 'settings/forms';
import {
  LABEL_ADDRESS,
  LABEL_ADDRESS_ERROR,
  LABEL_CANCEL_BUTTON,
  LABEL_CITY,
  LABEL_CITY_ERROR,
  LABEL_CIVILITY_ERROR,
  LABEL_COPURCHASER,
  LABEL_COUNTRY,
  LABEL_COUNTRY_ERROR,
  LABEL_CURRENT_SITUATION_ERROR,
  LABEL_DATE_OF_BIRTH,
  LABEL_DATE_OF_BIRTH_ERROR,
  LABEL_EMAIL,
  LABEL_EMAIL_ERROR,
  LABEL_EMAIL_SAME_TEST,
  LABEL_FAMILY_STATUS,
  LABEL_FAMILY_STATUS_COHABITATION,
  LABEL_FAMILY_STATUS_DIVORCED,
  LABEL_FAMILY_STATUS_ERROR,
  LABEL_FAMILY_STATUS_MARRIED,
  LABEL_FAMILY_STATUS_PACS,
  LABEL_FAMILY_STATUS_SINGLE,
  LABEL_FAMILY_STATUS_WIDOW,
  LABEL_FIRSTNAME,
  LABEL_FIRSTNAME_ERROR,
  LABEL_FORM_FIELDS_REQUIRED,
  LABEL_LASTNAME,
  LABEL_LASTNAME_ERROR,
  LABEL_LAST_STEP,
  LABEL_MADAME,
  LABEL_MISTER,
  LABEL_MOBILE_PHONE,
  LABEL_NATIONALITY,
  LABEL_NATIONALITY_ERROR,
  LABEL_NEXT_STEP,
  LABEL_PHONE_NUMBER_ERROR,
  LABEL_PHONE_NUMBER_MOBILE_FORMAT_TEST,
  LABEL_PHONE_NUMBER_SAME_TEST,
  LABEL_PLACE_OF_BIRTH,
  LABEL_PLACE_OF_BIRTH_ERROR,
  LABEL_PURCHASER,
  LABEL_SITUATION,
  LABEL_SITUATION_HOSTED,
  LABEL_SITUATION_INVESTOR,
  LABEL_SITUATION_MULTI_OWNER,
  LABEL_SITUATION_OWNER,
  LABEL_SITUATION_TENANT,
  LABEL_ZIPCODE,
  LABEL_ZIPCODE_ERROR,
} from 'settings/labels';
import countries from 'settings/countries';
import nationalities from 'settings/nationalities';
import { USER_GENDER_FEMALE, USER_GENDER_MALE } from 'settings/user';

import { CityAutocomplete } from 'commonUi/CityAutocomplete/CityAutocomplete';
import { Checkbox } from 'commonUi/Checkbox/Checkbox';

import styles from '../PageAction.module.scss';

export default function FormPurchaser({
  name,
  defaultValues,
  onHandleClose,
  onClickOnNextButton,
  onClickOnBeforeButton,
  setCountryCode,
}: FormPurchaserType) {
  const { control, clearErrors, watch, formState } = useFormContext();
  const { errors } = formState;

  const coPurchaserDataNeeded = watch(`${name}.${FIELD_CO_PROSPECT}`);

  const isCoPurchaserRequired = !(name === FIELDSET_CO_PROSPECT && !coPurchaserDataNeeded);

  function getEmailHelperText() {
    if (isCoPurchaserRequired) {
      if (errors?.[name]?.[FIELD_PROSPECT_EMAIL]?.type === LABEL_EMAIL_SAME_TEST) {
        return errors?.[name]?.[FIELD_PROSPECT_EMAIL]?.message ?? LABEL_EMAIL_ERROR;
      }
      return errors?.[name]?.[FIELD_PROSPECT_EMAIL] && LABEL_EMAIL_ERROR;
    }
    return null;
  }

  function getPhoneNumberHelperText() {
    if (isCoPurchaserRequired) {
      if (errors?.[name]?.[FIELD_PROSPECT_PHONE]?.type === LABEL_PHONE_NUMBER_MOBILE_FORMAT_TEST) {
        return errors?.[name]?.[FIELD_PROSPECT_PHONE]?.message ?? LABEL_PHONE_NUMBER_ERROR;
      }
      if (errors?.[name]?.[FIELD_PROSPECT_PHONE]?.type === LABEL_PHONE_NUMBER_SAME_TEST) {
        return errors?.[name]?.[FIELD_PROSPECT_PHONE]?.message ?? LABEL_PHONE_NUMBER_ERROR;
      }
      return errors?.[name]?.[FIELD_PROSPECT_PHONE] && LABEL_PHONE_NUMBER_ERROR;
    }
    return null;
  }

  return (
    <>
      <div className={styles.form}>
        <div className={styles.title}>
          {name === FIELDSET_PROSPECT && LABEL_PURCHASER}
          {name === FIELDSET_CO_PROSPECT && (
            <>
              <Controller
                name={`${name}.${FIELD_CO_PROSPECT}`}
                control={control}
                render={props => (
                  <Checkbox
                    checked={props?.field?.value}
                    handleChange={ev => {
                      clearErrors(FIELDSET_CO_PROSPECT);
                      props?.field.onChange(ev.target.checked);
                    }}
                    {...props}
                    color="primary"
                  />
                )}
              />
              {LABEL_COPURCHASER}
            </>
          )}
        </div>
        <div className={styles.input}>
          <Controller
            name={`${name}.${FIELD_PROSPECT_CIVILITY}`}
            control={control}
            defaultValue={defaultValues.civility}
            render={props => (
              <RadioGroup
                error={Boolean(errors?.[name]?.[FIELD_PROSPECT_CIVILITY])}
                helperText={errors?.[name]?.[FIELD_PROSPECT_CIVILITY] && LABEL_CIVILITY_ERROR}
                value={props?.field.value}
                onChange={ev => props?.field.onChange(ev.target.value)}
                radioLabel={
                  <>
                    <RadioLabel
                      disabled={!isCoPurchaserRequired}
                      error={Boolean(errors?.[name]?.[FIELD_PROSPECT_CIVILITY])}
                      value={USER_GENDER_MALE}
                      label={LABEL_MISTER}
                    />
                    <RadioLabel
                      disabled={!isCoPurchaserRequired}
                      error={Boolean(errors?.[name]?.[FIELD_PROSPECT_CIVILITY])}
                      value={USER_GENDER_FEMALE}
                      label={LABEL_MADAME}
                    />
                  </>
                }
              />
            )}
          />
        </div>
        <div className={styles.grid}>
          <div className={styles.input}>
            <Controller
              name={`${name}.${FIELD_PROSPECT_LASTNAME}`}
              control={control}
              defaultValue={defaultValues.lastname}
              render={props => (
                <TextField
                  error={Boolean(errors?.[name]?.[FIELD_PROSPECT_LASTNAME])}
                  helperText={errors?.[name]?.[FIELD_PROSPECT_LASTNAME] && LABEL_LASTNAME_ERROR}
                  label={LABEL_LASTNAME}
                  notchedOutlineInvisible={!isCoPurchaserRequired}
                  onChange={ev => props?.field.onChange(ev.target.value)}
                  required={isCoPurchaserRequired}
                  disabled={!isCoPurchaserRequired}
                  value={props?.field.value}
                />
              )}
            />
          </div>
          <div className={styles.input}>
            <Controller
              name={`${name}.${FIELD_PROSPECT_FIRSTNAME}`}
              defaultValue={defaultValues.firstname}
              control={control}
              render={props => (
                <TextField
                  error={Boolean(errors?.[name]?.[FIELD_PROSPECT_FIRSTNAME])}
                  helperText={errors?.[name]?.[FIELD_PROSPECT_FIRSTNAME] && LABEL_FIRSTNAME_ERROR}
                  label={LABEL_FIRSTNAME}
                  notchedOutlineInvisible={!isCoPurchaserRequired}
                  onChange={ev => props?.field.onChange(ev.target.value)}
                  required={isCoPurchaserRequired}
                  disabled={!isCoPurchaserRequired}
                  value={props?.field.value}
                />
              )}
            />
          </div>
          <div className={styles.input}>
            <Controller
              name={`${name}.${FIELD_PROSPECT_EMAIL}`}
              defaultValue={defaultValues.email}
              control={control}
              render={props => (
                <TextField
                  error={Boolean(errors?.[name]?.[FIELD_PROSPECT_EMAIL])}
                  helperText={getEmailHelperText()}
                  label={LABEL_EMAIL}
                  notchedOutlineInvisible={!isCoPurchaserRequired}
                  onChange={ev => props?.field.onChange(ev.target.value)}
                  required={isCoPurchaserRequired}
                  disabled={!isCoPurchaserRequired}
                  value={props?.field.value}
                />
              )}
            />
          </div>
          <div className={styles.input}>
            <Controller
              name={`${name}.${FIELD_PROSPECT_PHONE}`}
              defaultValue={defaultValues.phoneNumber}
              control={control}
              render={props => (
                <PhoneField
                  country="fr"
                  error={Boolean(errors?.[name]?.[FIELD_PROSPECT_PHONE])}
                  helperText={getPhoneNumberHelperText()}
                  id="telephone"
                  inputProps={{
                    name: FIELD_PROSPECT_PHONE,
                    required: true,
                  }}
                  onChangeInput={ev => props?.field.onChange(ev.target.value)}
                  placeholder={LABEL_MOBILE_PHONE}
                  disabled={!isCoPurchaserRequired}
                  value={props?.field.value}
                  setCountryCode={setCountryCode}
                />
              )}
            />
          </div>
          <div className={styles.input}>
            <Controller
              name={`${name}.${FIELD_PROSPECT_NATIONALITY}`}
              control={control}
              defaultValue={
                defaultValues.nationality ? nationalities[defaultValues.nationality] : null
              }
              render={props => (
                <SelectField
                  id={FIELD_PROSPECT_NATIONALITY}
                  error={Boolean(errors?.[name]?.[FIELD_PROSPECT_NATIONALITY]?.message)}
                  helperText={
                    errors?.[name]?.[FIELD_PROSPECT_NATIONALITY] && LABEL_NATIONALITY_ERROR
                  }
                  label={LABEL_NATIONALITY}
                  onChange={optionValue => {
                    props?.field.onChange(optionValue);
                  }}
                  options={Object.entries(nationalities)
                    .sort(([a], [b]) => a.localeCompare(b))
                    .map(([value, key]) => ({ value, key }))}
                  required={isCoPurchaserRequired}
                  disabled={!isCoPurchaserRequired}
                  value={props?.field.value}
                />
              )}
            />
          </div>
          <div className={styles.input}>
            <Controller
              name={`${name}.${FIELD_PROSPECT_BIRTH_DATE}`}
              control={control}
              defaultValue={defaultValues.birthDate || null}
              render={props => (
                <DateField
                  error={Boolean(errors?.[name]?.[FIELD_PROSPECT_BIRTH_DATE]?.message)}
                  helperText={
                    errors?.[name]?.[FIELD_PROSPECT_BIRTH_DATE] && LABEL_DATE_OF_BIRTH_ERROR
                  }
                  id={FIELD_PROSPECT_BIRTH_DATE}
                  label={LABEL_DATE_OF_BIRTH}
                  name={`${name}.${FIELD_PROSPECT_BIRTH_DATE}`}
                  notchedOutlineInvisible={!isCoPurchaserRequired}
                  onChange={props?.field.onChange}
                  required={isCoPurchaserRequired}
                  disabled={!isCoPurchaserRequired}
                  value={props?.field.value}
                  enableToolbar
                  disableFuture
                />
              )}
            />
          </div>
          <div className={styles.input}>
            <Controller
              name={`${name}.${FIELD_PROSPECT_FAMILY_STATUS}`}
              defaultValue={defaultValues.status}
              control={control}
              render={props => (
                <SelectField
                  id={FIELD_PROSPECT_FAMILY_STATUS}
                  error={Boolean(errors?.[name]?.[FIELD_PROSPECT_FAMILY_STATUS])}
                  helperText={
                    errors?.[name]?.[FIELD_PROSPECT_FAMILY_STATUS] && LABEL_FAMILY_STATUS_ERROR
                  }
                  label={LABEL_FAMILY_STATUS}
                  onChange={optionValue => {
                    props?.field.onChange(optionValue);
                  }}
                  options={[
                    { value: LABEL_FAMILY_STATUS_SINGLE, key: 'celibataire' },
                    { value: LABEL_FAMILY_STATUS_MARRIED, key: 'marie' },
                    { value: LABEL_FAMILY_STATUS_COHABITATION, key: 'concubinage' },
                    { value: LABEL_FAMILY_STATUS_DIVORCED, key: 'Divorcé' },
                    { value: LABEL_FAMILY_STATUS_PACS, key: 'PACS' },
                    { value: LABEL_FAMILY_STATUS_WIDOW, key: 'Veuf' },
                  ]}
                  required={isCoPurchaserRequired}
                  disabled={!isCoPurchaserRequired}
                  value={props?.field.value}
                />
              )}
            />
          </div>
          <div className={styles.input}>
            <Controller
              name={`${name}.${FIELD_PROSPECT_BIRTH_PLACE}`}
              control={control}
              defaultValue={defaultValues.birthPlace}
              render={props => (
                <CityAutocomplete
                  error={Boolean(errors?.[name]?.[FIELD_PROSPECT_BIRTH_PLACE])}
                  helperText={
                    errors?.[name]?.[FIELD_PROSPECT_BIRTH_PLACE] && LABEL_PLACE_OF_BIRTH_ERROR
                  }
                  label={LABEL_PLACE_OF_BIRTH}
                  notchedOutlineInvisible={!isCoPurchaserRequired}
                  onChange={city => {
                    props?.field.onChange(city);
                  }}
                  required={isCoPurchaserRequired}
                  disabled={!isCoPurchaserRequired}
                  value={props?.field.value}
                />
              )}
            />
          </div>
          <div className={styles.input}>
            <Controller
              name={`${name}.${FIELD_PROSPECT_SITUATION}`}
              defaultValue={defaultValues.situation}
              control={control}
              render={props => (
                <SelectField
                  id={FIELD_PROSPECT_SITUATION}
                  error={Boolean(errors?.[name]?.[FIELD_PROSPECT_SITUATION])}
                  helperText={
                    errors?.[name]?.[FIELD_PROSPECT_SITUATION] && LABEL_CURRENT_SITUATION_ERROR
                  }
                  label={LABEL_SITUATION}
                  onChange={optionValue => {
                    props?.field.onChange(optionValue);
                  }}
                  options={[
                    { value: LABEL_SITUATION_HOSTED, key: 'Hebergé' },
                    { value: LABEL_SITUATION_TENANT, key: 'locataire' },
                    { value: LABEL_SITUATION_OWNER, key: 'proprietaire' },
                    { value: LABEL_SITUATION_MULTI_OWNER, key: 'multi-proprietaire' },
                    { value: LABEL_SITUATION_INVESTOR, key: 'investisseur' },
                  ]}
                  required={isCoPurchaserRequired}
                  disabled={!isCoPurchaserRequired}
                  value={props?.field.value}
                />
              )}
            />
          </div>
        </div>
        <div className={styles.grid}>
          <div className={styles.input}>
            <Controller
              name={`${name}.${FIELD_PROSPECT_ADDRESS}`}
              control={control}
              defaultValue={defaultValues.address}
              render={props => (
                <TextField
                  error={Boolean(errors?.[name]?.[FIELD_PROSPECT_ADDRESS])}
                  helperText={errors?.[name]?.[FIELD_PROSPECT_ADDRESS] && LABEL_ADDRESS_ERROR}
                  label={LABEL_ADDRESS}
                  notchedOutlineInvisible={!isCoPurchaserRequired}
                  onChange={ev => props?.field.onChange(ev.target.value)}
                  required={isCoPurchaserRequired}
                  disabled={!isCoPurchaserRequired}
                  value={props?.field.value}
                />
              )}
            />
          </div>
          <div className={styles.input}>
            <Controller
              name={`${name}.${FIELD_PROSPECT_CITY}`}
              control={control}
              defaultValue={defaultValues.city}
              render={props => (
                <CityAutocomplete
                  error={Boolean(errors?.[name]?.[FIELD_PROSPECT_CITY])}
                  helperText={errors?.[name]?.[FIELD_PROSPECT_CITY] && LABEL_CITY_ERROR}
                  label={LABEL_CITY}
                  notchedOutlineInvisible={!isCoPurchaserRequired}
                  onChange={city => {
                    props?.field.onChange(city);
                  }}
                  required={isCoPurchaserRequired}
                  disabled={!isCoPurchaserRequired}
                  value={props?.field.value}
                />
              )}
            />
          </div>
          <div className={styles.input}>
            <Controller
              name={`${name}.${FIELD_PROSPECT_ZIPCODE}`}
              control={control}
              defaultValue={defaultValues.codePostal}
              render={props => (
                <TextField
                  error={Boolean(errors?.[name]?.[FIELD_PROSPECT_ZIPCODE])}
                  helperText={errors?.[name]?.[FIELD_PROSPECT_ZIPCODE] && LABEL_ZIPCODE_ERROR}
                  label={LABEL_ZIPCODE}
                  notchedOutlineInvisible={!isCoPurchaserRequired}
                  onChange={ev => {
                    if (ev.target.value.match(/\D/)) {
                      ev.preventDefault();
                    } else {
                      props?.field.onChange(ev.target.value);
                      clearErrors(FIELD_PROSPECT_ZIPCODE);
                    }
                  }}
                  type="text"
                  required={isCoPurchaserRequired}
                  disabled={!isCoPurchaserRequired}
                  value={props?.field.value}
                />
              )}
            />
          </div>
          <div className={styles.input}>
            <Controller
              name={`${name}.${FIELD_PROSPECT_COUNTRY}`}
              control={control}
              defaultValue={defaultValues.country}
              render={props => (
                <SelectField
                  id={FIELD_PROSPECT_COUNTRY}
                  error={Boolean(errors?.[name]?.[FIELD_PROSPECT_COUNTRY])}
                  helperText={errors?.[name]?.[FIELD_PROSPECT_COUNTRY] && LABEL_COUNTRY_ERROR}
                  label={LABEL_COUNTRY}
                  onChange={optionValue => {
                    props?.field.onChange(optionValue);
                  }}
                  options={Object.entries(countries)
                    .sort(([, a], [, b]) => a.localeCompare(b))
                    .map(([key, value]) => ({ value, key: key.toUpperCase() }))}
                  required={isCoPurchaserRequired}
                  disabled={!isCoPurchaserRequired}
                  value={props?.field.value}
                />
              )}
            />
          </div>
        </div>

        <span className={styles.additionalInformation}>* {LABEL_FORM_FIELDS_REQUIRED}</span>
      </div>
      <div className={styles.footer}>
        <>
          <div className={styles.cancelButton}>
            {name === FIELDSET_PROSPECT && (
              <Button fullWidth variant="contained" color="secondary" onClick={onHandleClose}>
                {LABEL_CANCEL_BUTTON}
              </Button>
            )}
            {onClickOnBeforeButton && (
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={onClickOnBeforeButton}
              >
                {LABEL_LAST_STEP}
              </Button>
            )}
          </div>
          <div className={styles.createButton}>
            <Button fullWidth variant="contained" color="primary" onClick={onClickOnNextButton}>
              {LABEL_NEXT_STEP}
            </Button>
          </div>
        </>
      </div>
    </>
  );
}

export type FormPurchaserType = {
  name: typeof FIELDSET_PROSPECT | typeof FIELDSET_CO_PROSPECT;
  defaultValues: Record<string, any>;
  onClickOnNextButton: () => void;
  onHandleClose?: any;
  onClickOnBeforeButton?: () => void;
  setCountryCode: (countryCode: string) => void;
};
