import React, { useContext, useEffect, useState } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { mutate } from 'swr';
import classnames from 'classnames';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { IconButton } from '@material-ui/core';

import { testMobilePhone } from 'services/yup';
import {
  CONTRACT_KITCHEN_INCLUDED,
  CONTRACT_KITCHEN_NOT_SELECTED,
  CONTRACT_KITCHEN_SELECTED,
} from 'settings/app';
import countries from 'settings/countries';
import { USER_GENDER_MAP } from 'settings/user';
import { DEFAULT_SECURITY_DEPOSIT } from 'settings/activity';
import {
  FIELDSET_CO_PROSPECT,
  FIELDSET_PROSPECT,
  FIELD_CO_PROSPECT,
  FIELD_CREDIT_AMOUNT,
  FIELD_CREDIT_CONTRIBUTION,
  FIELD_CREDIT_FUNDING,
  FIELD_DEPOSIT_AMOUNT,
  FIELD_DEPOSIT_CHECK_DATE,
  FIELD_DEPOSIT_MODE,
  FIELD_FINANCIAL_CONDITIONS,
  FIELD_FINANCIAL_DOCUMENTS,
  FIELD_FINANCIAL_DUTY_FREE,
  FIELD_FINANCIAL_FEES,
  FIELD_FINANCIAL_KITCHEN,
  FIELD_FINANCIAL_NOTE,
  FIELD_FINANCIAL_SIGNATURE,
  FIELD_FISCALITY,
  FIELD_LOT_FISCALITY_HIDDEN,
  FIELD_LOT_PRICE,
  FIELD_PROSPECT_ADDRESS,
  FIELD_PROSPECT_BIRTH_DATE,
  FIELD_PROSPECT_BIRTH_PLACE,
  FIELD_PROSPECT_CITY,
  FIELD_PROSPECT_CIVILITY,
  FIELD_PROSPECT_COUNTRY,
  FIELD_PROSPECT_DESTINATION,
  FIELD_PROSPECT_EMAIL,
  FIELD_PROSPECT_FAMILY_STATUS,
  FIELD_PROSPECT_FIRSTNAME,
  FIELD_PROSPECT_LASTNAME,
  FIELD_PROSPECT_NATIONALITY,
  FIELD_PROSPECT_PHONE,
  FIELD_PROSPECT_SITUATION,
  FIELD_PROSPECT_ZIPCODE,
  FIELD_PTZ,
} from 'settings/forms';
import { KITCHEN_TYPE_INLUDED } from 'settings/kitchen';
import {
  LABEL_ADD_COPURCHASER_CONFIRM_TEXT,
  LABEL_COPURCHASER_INFORMATIONS,
  LABEL_EMAIL_SAME_ERROR,
  LABEL_EMAIL_SAME_TEST,
  LABEL_FINANCIAL_INFORMATIONS,
  LABEL_FORM_CONTRACT_PREBOOK_ERROR_MESSAGE_DEGRADED,
  LABEL_FORM_CONTRACT_PREBOOK_ERROR_TITLE,
  LABEL_FORM_CONTRACT_PREBOOK_ERROR_TITLE_DEGRADED,
  LABEL_FORM_ERROR_CONTENT,
  LABEL_FORM_ERROR_DEFAULT,
  LABEL_FORM_GENERATE_CONTRACT_MISSING_DOCUMENTS,
  LABEL_GENERATE_CONTRACT,
  LABEL_PHONE_NUMBER_ERROR,
  LABEL_PHONE_NUMBER_MOBILE_ERROR,
  LABEL_PHONE_NUMBER_MOBILE_FORMAT_TEST,
  LABEL_PHONE_NUMBER_SAME_ERROR,
  LABEL_PHONE_NUMBER_SAME_TEST,
  LABEL_PURCHASER_INFORMATIONS,
  LABEL_QUIT,
} from 'settings/labels';
import { TAX_TYPE_LMNP, TAX_TYPE_LMNP_MANAGED } from 'settings/taxes';
import { TOKEN_LOT_NUMBER, TOKEN_PROGRAM_NAME } from 'settings/token';

import { axiosViCrmInstance } from 'api/viCrmApi/axiosInstance';

import type { Activity } from 'api/vi3pAPI/apiTypes/ActivityType';
import type { ContractGenerationType } from 'api/viCrmApi/apiTypes/Contract';
import type { PreBookingPatchType, PrebookKitchenTypeType } from 'api/viCrmApi/apiTypes/Prebook';

import { getCommercialApiBaseUrl } from 'services/activities';
import { regexEmail, regexPercentageFees } from 'services/constraints';
import { generateStandardGUID } from 'services/encrypt';
import { replaceTokens } from 'services/formatter';
import { error } from 'services/log';
import { taxPayloadNames } from 'services/taxes';

import ResponsiveContext from 'modules/App/Contexts/ResponsiveContext';
import settingsContext from 'modules/App/Contexts/SettingsContext';
import userContext from 'modules/App/Contexts/userContext';

import { useActivityChoices, usePreBookings } from 'hooks/useActivities';
import useLoadProgramAndLot from 'hooks/useLoadProgramAndLot';

import SpinLoader from 'commonUi/SpinLoader/SpinLoader';
import { LotCard } from 'commonUi/LotCard/LotCard';
import Stepper from 'commonUi/Stepper/Stepper';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';
import ModalConfirm from 'commonUi/ModalConfirm/ModalConfirm';
import Icon from 'sharedModulesV4/common/components/Atoms/Icon';
import LotPromotion from 'commonUi/ListLotV2/LotPromotion/LotPromotion';

import TagCommander from 'modules/App/TagCommander/TagCommander';
import TagCommanderEvent from 'modules/App/TagCommander/TagCommanderEvent';

import { ConfirmationContract } from './ConfirmationContract';
import FormPurchaser from './FormPurchaser';
import FormFinancialInformations from './FormFinancialInformations';

import styles from '../PageAction.module.scss';

function activityHasContract(activity: Activity) {
  return (
    (activity.field_documents && Object.keys(activity.field_documents).length !== 0) ||
    activity?.field_documents_ac?.length !== 0
  );
}

interface Prospect {
  civility: string;
  lastname: string;
  firstname: string;
  email: string;
  phoneNumber?: string;
  birthDate?: string;
  birthPlace?: string;
  address?: string;
  city?: string;
  codePostal?: string;
  country?: string;
  status?: string;
  nationality?: string;
  situation?: string;
}
function getProspectDefaultValues(activity: Activity): [Prospect, Prospect] {
  const prospect = {
    // sometimes we have the short form, sometimes the long one
    civility: USER_GENDER_MAP.get(activity.field_prospectcivilite_ac) as string,
    lastname: activity.field_prospectnom_ac,
    firstname: activity.field_prospectprenom_ac,
    email: activity.field_prospectmail_ac,
    phoneNumber: /\d/.test(activity.field_prospecttelephone_ac)
      ? activity.field_prospecttelephone_ac?.replace(/\+/g, '')
      : '',
  };
  const coProspect = {
    // sometimes we have the short form, sometimes the long one
    civility: USER_GENDER_MAP.get(prospect.civility) as string,
    lastname: activity.field_coprospectnom_ac,
    firstname: activity.field_coprospectprenom_ac,
    email: activity.field_coprospectmail_ac,
    phoneNumber: /\d/.test(activity.field_coprospecttelephone_ac)
      ? activity.field_coprospecttelephone_ac?.replace(/\+/g, '')
      : '',
  };

  if (activityHasContract(activity)) {
    return [
      {
        ...prospect,
        birthDate: activity.field_prospectdatenaissance_ac,
        birthPlace: activity.field_prospectlieunaissance_ac,
        address: activity.field_prospectadresse_ac,
        city: activity.field_prospectville_ac,
        codePostal: activity.field_prospectcodepostal_ac,
        country: activity.field_prospectpays_ac,
        status: activity.field_prospectstatutfamilial_ac,
        nationality: activity.field_prospectnationalite_ac,
        situation: activity.field_prospectsituationactuelle_ac,
      },
      {
        ...coProspect,
        birthDate: activity.field_coprospectdatenaissance_ac,
        birthPlace: activity.field_coprospectlieunaissance_ac,
        address: activity.field_coprospectadresse_ac,
        city: activity.field_coprospectville_ac,
        codePostal: activity.field_coprospectcodepostal_ac,
        country: activity.field_coprospectpays_ac,
        status: activity.field_coprospectstatutfamilial_ac,
        nationality: activity.field_coprospectnationalite_ac,
        situation: activity.field_coprospectsituationactuelle_ac,
      },
    ];
  }
  return [prospect, coProspect];
}

export function FormGenerateContract({
  history,
  match,
}: RouteComponentProps<{ prebookIdCrm?: string }>) {
  const { prebookIdCrm } = match.params;

  const [isSuccess, setIsSuccess] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [isCoAcquereurModal, setIsCoAcquereurModal] = useState(false);
  const [errorLabels, setErrorLabels] = useState<{ title: string; message: string } | undefined>();
  const [prospectCountryCode, setProspectCountryCode] = useState('FR');
  const [coProspectCountryCode, setCoProspectCountryCode] = useState('FR');

  const { isResponsive } = useContext(ResponsiveContext);
  const { settings } = useContext(settingsContext);
  const { activityUrls, contactPrescriptor } = useContext(userContext);
  const { data: myPrebooks } = usePreBookings('active');
  const prebook = myPrebooks?.find(prebook => prebook.field_idcrm_ac === prebookIdCrm);

  const { choices } = useActivityChoices(
    { preBookingId: prebookIdCrm },
    {
      refreshWhenHidden: false,
      revalidateOnFocus: false,
    }
  );

  const { lot, program, isLoading } = useLoadProgramAndLot(
    prebook?.field_referenceprogramme_ac,
    prebook?.field_numerolot_ac
  );

  const methods = useForm({
    resolver: yupResolver(formSchema(prospectCountryCode, coProspectCountryCode)),
    mode: 'onChange',
    shouldUnregister: false,
  });

  const annexesComplementaires = Object.fromEntries(
    choices?.documents?.annexesComplementaires?.map(document => [
      document.replace(/\W/g, ''),
      document,
    ]) ?? []
  );

  function handleClose() {
    history.push('/');
  }

  function handleConfirm(wantCoSubscriber: boolean) {
    window.scrollTo(0, 0);
    // want subscriber from step 0 => go step 1
    if (wantCoSubscriber) {
      methods?.setValue(`${FIELDSET_CO_PROSPECT}.${FIELD_CO_PROSPECT}`, true, {
        shouldDirty: true,
      });
      return setActiveStep(1);
    }

    // don't want subscriber from step 2 => go step 0
    if (!wantCoSubscriber && activeStep === 2) return setActiveStep(0);

    // don't want subscriber from step 0 => go step 2
    methods?.setValue(`${FIELDSET_CO_PROSPECT}.${FIELD_CO_PROSPECT}`, false, {
      shouldDirty: true,
    });
    return setActiveStep(2);
  }

  function getSubmitHandler(lotNumber: string, programRef: string, programName?: string) {
    return methods.handleSubmit(async values => {
      let packKitchen: PrebookKitchenTypeType = CONTRACT_KITCHEN_NOT_SELECTED;
      if (values[FIELD_FINANCIAL_KITCHEN]) {
        packKitchen = CONTRACT_KITCHEN_SELECTED;
      }
      if (
        choices.packs.maCuisineEquipee.eligible &&
        choices.packs.maCuisineEquipee.type === KITCHEN_TYPE_INLUDED
      ) {
        packKitchen = CONTRACT_KITCHEN_INCLUDED;
      }

      // STEP 1 : Update Prebook
      // STEP 2 : If update OK, generate new contract
      try {
        setLoadingSubmit(true);
        setErrorLabels(undefined);
        const patchPayload: PreBookingPatchType = {
          acquereur: {
            civilite: values[FIELDSET_PROSPECT][FIELD_PROSPECT_CIVILITY],
            nom: values[FIELDSET_PROSPECT][FIELD_PROSPECT_LASTNAME],
            prenom: values[FIELDSET_PROSPECT][FIELD_PROSPECT_FIRSTNAME],
            nationalite: values[FIELDSET_PROSPECT][FIELD_PROSPECT_NATIONALITY],
            dateDeNaissance: values[FIELDSET_PROSPECT][FIELD_PROSPECT_BIRTH_DATE],
            lieuDeNaissance: values[FIELDSET_PROSPECT][FIELD_PROSPECT_BIRTH_PLACE],
            statutFamiliale: values[FIELDSET_PROSPECT][FIELD_PROSPECT_FAMILY_STATUS],
            situationActuelle: values[FIELDSET_PROSPECT][FIELD_PROSPECT_SITUATION],
            adresse: values[FIELDSET_PROSPECT][FIELD_PROSPECT_ADDRESS],
            codePostal: values[FIELDSET_PROSPECT][FIELD_PROSPECT_ZIPCODE],
            ville: values[FIELDSET_PROSPECT][FIELD_PROSPECT_CITY],
            pays: countries[values[FIELDSET_PROSPECT][FIELD_PROSPECT_COUNTRY].toLowerCase()],
            email: values[FIELDSET_PROSPECT][FIELD_PROSPECT_EMAIL],
            telephone: values[FIELDSET_PROSPECT][FIELD_PROSPECT_PHONE]
              ? `+${values[FIELDSET_PROSPECT][FIELD_PROSPECT_PHONE]}`
              : values[FIELDSET_PROSPECT][FIELD_PROSPECT_PHONE],
            destinationAchat: values[FIELD_PROSPECT_DESTINATION],
          },
          ...(values[FIELDSET_CO_PROSPECT]?.[FIELD_CO_PROSPECT]
            ? {
                coAcquereur: {
                  civilite: values[FIELDSET_CO_PROSPECT][FIELD_PROSPECT_CIVILITY],
                  nom: values[FIELDSET_CO_PROSPECT][FIELD_PROSPECT_LASTNAME],
                  prenom: values[FIELDSET_CO_PROSPECT][FIELD_PROSPECT_FIRSTNAME],
                  nationalite: values[FIELDSET_CO_PROSPECT][FIELD_PROSPECT_NATIONALITY],
                  dateDeNaissance: values[FIELDSET_CO_PROSPECT][FIELD_PROSPECT_BIRTH_DATE],
                  lieuDeNaissance: values[FIELDSET_CO_PROSPECT][FIELD_PROSPECT_BIRTH_PLACE],
                  statutFamiliale: values[FIELDSET_CO_PROSPECT][FIELD_PROSPECT_FAMILY_STATUS],
                  situationActuelle: values[FIELDSET_CO_PROSPECT][FIELD_PROSPECT_SITUATION],
                  adresse: values[FIELDSET_CO_PROSPECT][FIELD_PROSPECT_ADDRESS],
                  codePostal: values[FIELDSET_CO_PROSPECT][FIELD_PROSPECT_ZIPCODE],
                  ville: values[FIELDSET_CO_PROSPECT][FIELD_PROSPECT_CITY],
                  pays:
                    countries[values[FIELDSET_CO_PROSPECT][FIELD_PROSPECT_COUNTRY].toLowerCase()],
                  email: values[FIELDSET_CO_PROSPECT][FIELD_PROSPECT_EMAIL],
                  telephone: values[FIELDSET_CO_PROSPECT][FIELD_PROSPECT_PHONE]
                    ? `+${values[FIELDSET_CO_PROSPECT][FIELD_PROSPECT_PHONE]}`
                    : values[FIELDSET_CO_PROSPECT][FIELD_PROSPECT_PHONE],
                  destinationAchat: values[FIELD_PROSPECT_DESTINATION],
                },
              }
            : {}),
          financement: {
            aidesVente: {
              fraisNotaireOfferts: choices.aideVentes.fraisNotaireOfferts,
            },
            financementCredit: values[FIELD_CREDIT_FUNDING] === 'true',
            apportPersonnelCredit: values[FIELD_CREDIT_CONTRIBUTION],
            montantCredit: values[FIELD_CREDIT_AMOUNT],
            // https://dev.azure.com/vinci-immobilier/VIP/_workitems/edit/27265
            // This is SO dumb but VISTA is apparently unable to handle receiving LMNP Géré.
            // I'm sure there is absolutely NO DOUBT this won't break anything...
            dispositifFiscal:
              values[FIELD_FISCALITY] === taxPayloadNames[TAX_TYPE_LMNP_MANAGED]
                ? taxPayloadNames[TAX_TYPE_LMNP]
                : values[FIELD_FISCALITY],
            ...(values[FIELD_FINANCIAL_DUTY_FREE] !== undefined
              ? { financementHT: values[FIELD_FINANCIAL_DUTY_FREE] === '1' }
              : {}),
          },
          TVAReduite: false, // TODO data missing from the form & required by the API
          TVA: parseFloat(values[FIELD_LOT_PRICE]),
          PTZ: values[FIELD_PTZ] ? values[FIELD_PTZ] : false,
          TauxHonoraires: parseFloat(values[FIELD_FINANCIAL_FEES]),
          numeroLot: lotNumber,
          referenceProgramme: programRef,
          dateDeSignature: values[FIELD_FINANCIAL_SIGNATURE]?.toISOString(),
          depotDeGarantie: {
            modeDePaiement: values[FIELD_DEPOSIT_MODE],
            dateDeRemiseDuCheque: values[FIELD_DEPOSIT_CHECK_DATE]?.toISOString(),
            montant: values[FIELD_DEPOSIT_AMOUNT],
          },
          packs: {
            maCuisineEquipee: packKitchen,
          },
        };
        // TODO Move this into a `useGenerateContract` hook in `useActivities` (similar to `useCreatePreBooking` used in `FormPrebooking`) so we can more gracefully enforce the typing on the PATCH Payload
        const response = await axiosViCrmInstance.patch(
          `${getCommercialApiBaseUrl(
            !!settings.cdo?.activite_commerciale_api
          )}/pre-reservations/${prebookIdCrm}`,
          patchPayload
        );

        if (response.status === 200) {
          const postPayload: ContractGenerationType = {
            correlationId: generateStandardGUID(),
            application: 'VI3P',
            choixDocuments: {
              annexesComplementaires:
                values[FIELD_FINANCIAL_DOCUMENTS] &&
                Object.entries(values[FIELD_FINANCIAL_DOCUMENTS])
                  .filter(([, checked]) => checked)
                  .map(([document]) => annexesComplementaires[document]),
              conditionsParticulieres: [values[FIELD_FINANCIAL_CONDITIONS]],
              noticesDescriptives: [values[FIELD_FINANCIAL_NOTE]],
            },
          };
          if (!prebookIdCrm) {
            error('logic', 'Attempted to generate a contract without providing its pre-booking ID');
            return;
          }
          const responseGenerateContract = await axiosViCrmInstance.post(
            `${getCommercialApiBaseUrl(
              !!settings.cdo?.activite_commerciale_api
            )}/pre-reservations/${prebookIdCrm}/genererContrat`,
            postPayload
          );
          await mutate(activityUrls.preBookings);
          if (responseGenerateContract.status === 202) {
            setIsSuccess(true);
          }
        }
        // error case from CRM
        if (response.status === 202) {
          setErrorLabels({
            title: LABEL_FORM_CONTRACT_PREBOOK_ERROR_TITLE_DEGRADED,
            message: replaceTokens(LABEL_FORM_CONTRACT_PREBOOK_ERROR_MESSAGE_DEGRADED, {
              [TOKEN_LOT_NUMBER]: lotNumber,
              [TOKEN_PROGRAM_NAME]: programName,
            }),
          });
        }
      } catch (newError) {
        if (newError?.response?.status) {
          setErrorLabels({
            title: LABEL_FORM_CONTRACT_PREBOOK_ERROR_TITLE,
            message: newError.response.data?.message ?? LABEL_FORM_ERROR_CONTENT,
          });
        }
      } finally {
        setLoadingSubmit(false);
      }
    });
  }

  const hasMandatoryDocuments =
    choices?.documents?.conditionsParticulieres.length &&
    choices?.documents?.noticesDescriptives.length;

  useEffect(() => {
    // Reset on load to avoid old formState values when we edit the same contract after saving
    methods?.reset();
  }, []);

  useEffect(() => {
    if (choices?.documents && !hasMandatoryDocuments) {
      setActiveStep(2);
    }
  }, [JSON.stringify(choices?.documents)]);

  if (!prebookIdCrm || !prebook) {
    return null;
  }

  const [prospectDefaultValues, coProspectDefaultValues] = getProspectDefaultValues(prebook);

  async function handleAcquereurNext(prebook: Parameters<typeof activityHasContract>[0]) {
    const values = methods.getValues();

    await methods.trigger(FIELDSET_PROSPECT);
    if (!methods.formState.errors?.[FIELDSET_PROSPECT]) {
      // if co subscriber is already at true not show modal
      if (activityHasContract(prebook) && Object.values(coProspectDefaultValues).some(Boolean)) {
        methods?.setValue(`${FIELDSET_CO_PROSPECT}.${FIELD_CO_PROSPECT}`, true, {
          shouldDirty: true,
        });
        return setActiveStep(activeStep => activeStep + 1);
      }

      // if co subscriber is already at true not show modal
      if (values?.[FIELDSET_CO_PROSPECT]?.[FIELD_CO_PROSPECT]) {
        return setActiveStep(activeStep => activeStep + 1);
      }

      return setIsCoAcquereurModal(true);
    }
    return false;
  }

  async function handleCoAcquereurNext() {
    const values = methods.getValues();
    await methods.trigger(FIELDSET_CO_PROSPECT);
    if (
      !values?.[FIELDSET_CO_PROSPECT]?.[FIELD_CO_PROSPECT] ||
      (values?.[FIELDSET_CO_PROSPECT]?.[FIELD_CO_PROSPECT] &&
        !methods.formState.errors?.[FIELDSET_CO_PROSPECT])
    ) {
      setActiveStep(activeStep => activeStep + 1);
    }
  }

  return (
    <div className={styles.root}>
      <div className={classnames(styles.header, { [styles.headerSuccess]: isSuccess })}>
        <button className={styles.returnBtn} type="button" onClick={handleClose}>
          <Icon className={styles.returnBtnIcon} icon="arrow-left" />
          <span>{LABEL_QUIT}</span>
        </button>
        <div className={styles.name}>
          {!isResponsive && <Icon className={styles.returnBtnIcon} icon="reservation-contract" />}
          {LABEL_GENERATE_CONTRACT}
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.lotCardWrapper}>
          <div className={styles.lotCard}>
            <LotCard lot={lot} program={program} isLoading={isLoading} />
          </div>
        </div>
        {lot?.bloc_libre && (
          <div className={styles.promoContainer}>
            <LotPromotion lot={lot} />
          </div>
        )}
        <div className={styles.formContainer}>
          <FormProvider {...methods}>
            {!isSuccess && (
              <div className={styles.stepperContainer}>
                <Stepper
                  steps={[
                    LABEL_PURCHASER_INFORMATIONS,
                    LABEL_COPURCHASER_INFORMATIONS,
                    LABEL_FINANCIAL_INFORMATIONS,
                  ]}
                  activeStep={activeStep}
                  setStep={async (index: number) => {
                    // "click on before" case
                    if (activeStep > index) setActiveStep(activeStep => activeStep - 1);
                    else if (activeStep === index) setActiveStep(index);
                    else
                      switch (index) {
                        case 1: {
                          await handleAcquereurNext(prebook);
                          break;
                        }
                        case 2: {
                          await handleCoAcquereurNext();
                          break;
                        }
                        default:
                          setActiveStep(index);
                      }
                  }}
                />
              </div>
            )}
            {!choices?.documents && (
              <div className={styles.loader}>
                <SpinLoader loading />
              </div>
            )}
            {choices?.documents && !hasMandatoryDocuments && (
              <div className={styles.paragraph}>
                {LABEL_FORM_GENERATE_CONTRACT_MISSING_DOCUMENTS}
              </div>
            )}
            {!isSuccess && choices?.documents && (
              <form
                onSubmit={getSubmitHandler(
                  prebook.field_numerolot_ac,
                  prebook.field_referenceprogramme_ac,
                  prebook.field_nomprogramme_ac
                )}
              >
                {activeStep === 0 && (
                  <FormPurchaser
                    name={FIELDSET_PROSPECT}
                    defaultValues={prospectDefaultValues}
                    onHandleClose={handleClose}
                    onClickOnNextButton={() => handleAcquereurNext(prebook)}
                    setCountryCode={setProspectCountryCode}
                  />
                )}
                {activeStep === 1 && (
                  <FormPurchaser
                    name={FIELDSET_CO_PROSPECT}
                    defaultValues={coProspectDefaultValues}
                    onClickOnNextButton={handleCoAcquereurNext}
                    onClickOnBeforeButton={() => setActiveStep(activeStep => activeStep - 1)}
                    setCountryCode={setCoProspectCountryCode}
                  />
                )}
                {activeStep === 2 && (
                  <FormFinancialInformations
                    activity={prebook}
                    annexes={annexesComplementaires}
                    choices={choices}
                    contactPrescriptor={contactPrescriptor}
                    onClickOnBeforeButton={() => setActiveStep(activeStep => activeStep - 1)}
                    lotNumber={prebook.field_numerolot_ac}
                    programRef={prebook.field_referenceprogramme_ac}
                    loadingSubmit={loadingSubmit}
                    defaultValues={{
                      depositAmount: prebook.depot_garantie?.montant || DEFAULT_SECURITY_DEPOSIT,
                      depositMethod: prebook.depot_garantie?.mode_paiement,
                      depositCheckDate: prebook.depot_garantie?.remisecheque,
                      destination: prebook.field_prospectdestachat_ac,
                      fees: prebook.field_tauxhonorairesfinal_ac,
                      funding: prebook.field_financement_credit ? 'true' : 'false',
                      loanAmount: prebook.field_montant_credit,
                      loanContribution: prebook.field_apport_personnel_credit,
                      dutyFree: prebook.field_financement_ht || '1',
                      tva: prebook.field_tva,
                      signatureDate: prebook.field_datecontratsigne_client_ac || null,
                      tax: prebook.field_dispositiffiscal_ac,
                      ptz: prebook.field_ptz,
                      kitchen: prebook.field_cuisine_equipee_type,
                    }}
                    program={program}
                  />
                )}
              </form>
            )}
          </FormProvider>

          <ModalConfirm
            onCancel={() => {
              handleConfirm(false);
              setIsCoAcquereurModal(false);
            }}
            onConfirm={() => {
              handleConfirm(true);
              setIsCoAcquereurModal(false);
            }}
            open={isCoAcquereurModal}
            title={LABEL_ADD_COPURCHASER_CONFIRM_TEXT}
          />

          {isSuccess && (
            <ConfirmationContract prebookIdCrm={prebookIdCrm} handleClose={handleClose} />
          )}
          <div className={classnames(styles.footer, { [styles.errorFooter]: errorLabels })}>
            {!!errorLabels && (
              <div className={styles.errorContent}>
                <div className={styles.right}>
                  <IconButton
                    classes={{ root: styles.iconButton }}
                    disableRipple
                    onClick={() => setErrorLabels(undefined)}
                  >
                    <SvgIcon className={styles.svgIcon} iconId="icon-cross" />
                  </IconButton>
                </div>
                <div className={styles.center}>
                  <h4>{errorLabels.title}</h4>
                  <p>{errorLabels.message ?? LABEL_FORM_ERROR_DEFAULT}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <TagCommander
        navigation_pagename={
          isSuccess ? 'form_generation.confirmation' : `form_generation.step${activeStep + 1}`
        }
        navigation_template="generation"
        useEffectDeps={['navigation_template', 'navigation_pagename']}
      />
      <TagCommanderEvent
        isActive={!!errorLabels}
        navigation_pagename="form_generation.erreur"
        navigation_template="generation"
        useEffectDeps={['navigation_template', 'navigation_pagename']}
      />
    </div>
  );
}

const formSchema = (prospectCountryCode: string, coProspectCountryCode: string) => {
  return yup.object().shape({
    [FIELDSET_PROSPECT]: yup.object().shape({
      [FIELD_PROSPECT_CIVILITY]: yup.string().trim().required(),
      [FIELD_PROSPECT_LASTNAME]: yup.string().trim().required(),
      [FIELD_PROSPECT_FIRSTNAME]: yup.string().trim().required(),
      [FIELD_PROSPECT_EMAIL]: yup.string().required().matches(RegExp(regexEmail)),
      [FIELD_PROSPECT_PHONE]: yup
        .string()
        .trim()
        .required(LABEL_PHONE_NUMBER_ERROR)
        .test(LABEL_PHONE_NUMBER_MOBILE_FORMAT_TEST, LABEL_PHONE_NUMBER_MOBILE_ERROR, val =>
          testMobilePhone(val, prospectCountryCode)
        ),
      [FIELD_PROSPECT_NATIONALITY]: yup.string().trim().required(),
      [FIELD_PROSPECT_BIRTH_DATE]: yup.date().required(),
      [FIELD_PROSPECT_FAMILY_STATUS]: yup.string().trim().required(),
      [FIELD_PROSPECT_BIRTH_PLACE]: yup.string().trim().required(),
      [FIELD_PROSPECT_SITUATION]: yup.string().trim().required(),
      [FIELD_PROSPECT_ADDRESS]: yup.string().trim().required(),
      [FIELD_PROSPECT_CITY]: yup.string().trim().required(),
      [FIELD_PROSPECT_ZIPCODE]: yup.string().matches(/^\d+$/).required(),
      [FIELD_PROSPECT_COUNTRY]: yup.string().trim().required(),
    }),
    [FIELDSET_CO_PROSPECT]: yup
      .object()
      .notRequired()
      .default(undefined)
      .shape({
        [FIELD_CO_PROSPECT]: yup.boolean(),
        [FIELD_PROSPECT_CIVILITY]: yup.string().trim().when(FIELD_CO_PROSPECT, {
          is: true,
          then: yup.string().trim().required(),
        }),
        [FIELD_PROSPECT_LASTNAME]: yup.string().trim().when(FIELD_CO_PROSPECT, {
          is: true,
          then: yup.string().trim().required(),
        }),
        [FIELD_PROSPECT_FIRSTNAME]: yup.string().trim().when(FIELD_CO_PROSPECT, {
          is: true,
          then: yup.string().trim().required(),
        }),
        [FIELD_PROSPECT_EMAIL]: yup.string().when(FIELD_CO_PROSPECT, {
          is: true,
          then: yup
            .string()
            .required()
            .matches(RegExp(regexEmail))
            .test(
              LABEL_EMAIL_SAME_TEST,
              LABEL_EMAIL_SAME_ERROR,
              (value, context: any) =>
                // field "from" doesn't officially exist so we need to force the type on "context"
                value !== context.from?.[1].value[FIELDSET_PROSPECT]?.[FIELD_PROSPECT_EMAIL]
            ),
        }),
        [FIELD_PROSPECT_PHONE]: yup.string().when(FIELD_CO_PROSPECT, {
          is: true,
          then: yup
            .string()
            .required(LABEL_PHONE_NUMBER_ERROR)
            .test(LABEL_PHONE_NUMBER_MOBILE_FORMAT_TEST, LABEL_PHONE_NUMBER_MOBILE_ERROR, val =>
              testMobilePhone(val, coProspectCountryCode)
            )
            .test(
              LABEL_PHONE_NUMBER_SAME_TEST,
              LABEL_PHONE_NUMBER_SAME_ERROR,
              (value, context: any) =>
                // field "from" doesn't officially exist so we need to force the type on "context"
                value !== context.from?.[1].value[FIELDSET_PROSPECT]?.[FIELD_PROSPECT_PHONE]
            ),
        }),
        [FIELD_PROSPECT_NATIONALITY]: yup
          .string()
          .trim()
          .when(FIELD_CO_PROSPECT, {
            is: false,
            then: yup.string().nullable(),
          })
          .when(FIELD_CO_PROSPECT, {
            is: true,
            then: yup.string().trim().required(),
          }),
        [FIELD_PROSPECT_BIRTH_DATE]: yup.date().nullable().when(FIELD_CO_PROSPECT, {
          is: true,
          then: yup.date().required(),
        }),
        [FIELD_PROSPECT_FAMILY_STATUS]: yup.string().trim().when(FIELD_CO_PROSPECT, {
          is: true,
          then: yup.string().required(),
        }),
        [FIELD_PROSPECT_BIRTH_PLACE]: yup.string().trim().when(FIELD_CO_PROSPECT, {
          is: true,
          then: yup.string().required(),
        }),
        [FIELD_PROSPECT_SITUATION]: yup.string().trim().when(FIELD_CO_PROSPECT, {
          is: true,
          then: yup.string().required(),
        }),
        [FIELD_PROSPECT_ADDRESS]: yup.string().trim().when(FIELD_CO_PROSPECT, {
          is: true,
          then: yup.string().required(),
        }),
        [FIELD_PROSPECT_CITY]: yup.string().trim().when(FIELD_CO_PROSPECT, {
          is: true,
          then: yup.string().required(),
        }),
        [FIELD_PROSPECT_ZIPCODE]: yup
          .string()
          .trim()
          .when(FIELD_CO_PROSPECT, {
            is: true,
            then: yup.string().matches(/^\d+$/).required(),
          }),
        [FIELD_PROSPECT_COUNTRY]: yup.string().trim().when(FIELD_CO_PROSPECT, {
          is: true,
          then: yup.string().required(),
        }),
      }),
    [FIELD_PROSPECT_DESTINATION]: yup.string().trim().required(),
    [FIELD_FINANCIAL_FEES]: yup
      .string()
      .nullable()
      .matches(RegExp(regexPercentageFees))
      .when(FIELD_LOT_FISCALITY_HIDDEN, {
        is: (value: string) => !['LMNP', 'LMNP gere', 'Demembrement'].includes(value),
        then: yup.string().required(),
      }),
    [FIELD_FISCALITY]: yup.string(),
    'field-taxation': yup.string(),
    [FIELD_LOT_PRICE]: yup.string().trim().required(),
    [FIELD_FINANCIAL_SIGNATURE]: yup.date().required(),
    [FIELD_CREDIT_FUNDING]: yup.string().trim().required(),
    [FIELD_FINANCIAL_DUTY_FREE]: yup.string().trim().when(FIELD_FISCALITY, {
      is: 'LMNP',
      then: yup.string().required(),
    }),
    [FIELD_CREDIT_AMOUNT]: yup.mixed().when(FIELD_CREDIT_FUNDING, {
      is: 'true',
      then: yup.number().required(),
    }),
    [FIELD_CREDIT_CONTRIBUTION]: yup.mixed().when(FIELD_CREDIT_FUNDING, {
      is: 'true',
      then: yup.number().required(),
    }),
    [FIELD_DEPOSIT_AMOUNT]: yup.number().required(),
    [FIELD_DEPOSIT_MODE]: yup.string().trim().required(),
    [FIELD_DEPOSIT_CHECK_DATE]: yup.date().nullable().when(FIELD_DEPOSIT_MODE, {
      is: 'cheque',
      then: yup.date().required(),
    }),
    [FIELD_FINANCIAL_NOTE]: yup.string().trim().required(),
    [FIELD_FINANCIAL_CONDITIONS]: yup.string().trim().required(),
    // [FIELD_FINANCIAL_SIGNATURE]: yup
    //   .date()
    //   .min(subDays(new Date(), parseInt(contactPrescriptor?.dureePrereservations, 10)))
    //   .max(addDays(new Date(), parseInt(contactPrescriptor?.dureePrereservations, 10)))
    //   .required(),
    [FIELD_LOT_FISCALITY_HIDDEN]: yup.string().trim().required(),
  });
};
