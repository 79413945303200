import dayjs from 'dayjs';
import { error } from './log';

const today = new Date();

/** Add a number of days to the date */
export function addDays(date: Date | string | number, numberOfDaysToAdd: number | string) {
  return dayjs(date)
    .add((numberOfDaysToAdd as number) ?? 0, 'days')
    .toDate();
}

/** Subtract a number of days from the given date */
export function subDays(date: Date | string | number, numberOfDaysToSub: number | string) {
  return dayjs(date)
    .subtract((numberOfDaysToSub as number) ?? 0, 'days')
    .toDate();
}

/** Get the earliest date in the given set of Date-like objects */
export function dateMin(...dates: (Date | string | number | dayjs.Dayjs)[]) {
  return new Date(Math.min(...dates.map(date => dayjs(date).valueOf())));
}

/** Get the latest date in the given set of Date-like objects */
export function dateMax(...dates: (Date | string | number | dayjs.Dayjs)[]) {
  return new Date(Math.max(...dates.map(date => dayjs(date).valueOf())));
}

/**
 * Format a date with dayjs
 */
export function formatActivityDate(date: Date | string | undefined, format: string) {
  const dateParsed = dayjs(date);
  if (dateParsed.isValid()) {
    return dateParsed.format(format);
  }
  error('format', 'Invalid Date given');
  return '';
}

/**
 * Format a date as the third of the year it's in
 * Example: 2021-06-21 is formatted as '2T 2021'
 */
export function showQuarter(date: Date | string, fallback = '-') {
  const actualDate = !(date instanceof Date) && date?.length > 0 ? new Date(date) : date;

  if (!actualDate || !(actualDate instanceof Date)) {
    return fallback;
  }

  const q = Math.floor((actualDate.getMonth() + 3) / 3);
  const y = actualDate.getFullYear();
  if (actualDate < today) {
    return 'Immédiate';
  }
  return `${q}T ${y}`;
}

export function formatDate(dateStr: string): string {
  const parsedDate = new Date(Date.parse(dateStr));

  if (!parsedDate.getTime()) {
    return '';
  }
  const dateArray = parsedDate.toLocaleDateString('fr-FR').split('');
  dateArray.splice(dateArray.length - 4, 4, ...dateArray.slice(-2));

  const time = parsedDate.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' });

  return `${dateArray.join('')} ${time}`;
}

/** If the provided date doesn't have enough Hours to remain the same day when converting to Zulu (`.toJSON()`), add enough hours */
export function padDateTimeZone(date: Date) {
  const dateJS = dayjs(date);
  if (dateJS.hour() * 60 < dateJS.utcOffset()) {
    return dateJS.minute(dateJS.utcOffset()).toDate();
  }
  return date;
}
