import memoize from 'memoizee';

import { KITCHEN_TYPE_NONE } from 'settings/kitchen';
import {
  LOT_CELL_TYPE_ACTION as ACTION,
  LOT_CELL_TYPE_CHECKBOX as CHECKBOX,
  LOT_CELL_TYPE_DATE as DATE,
  HEADINGS_TYPE_DEFAULT,
  HEADINGS_TYPE_DEFAULT_REDUCED_PANEL,
  HEADINGS_TYPE_DEMEMBREMENT,
  HEADINGS_TYPE_DEMEMBREMENT_REDUCED_PANEL,
  HEADINGS_TYPE_HORS_DISPO,
  HEADINGS_TYPE_HORS_DISPO_REDUCED_PANEL,
  HEADINGS_TYPE_LMNP,
  HEADINGS_TYPE_LMNP_REDUCED_PANEL,
  HEADINGS_TYPE_REDUCED_PANEL,
  LOT_CELL_ID_KIND as ID_KIND,
  LOT_CELL_ID_TAX as ID_TAX,
  LOT_CELL_TYPE_KIND_WITH_PLAN as KIND_WITH_PLAN,
  LOT_CELL_TYPE_LOT as LOT,
  LOT_ARRAY_LENGTH,
  LOT_JSON_ANNEXES,
  LOT_JSON_ATTRIBUTION,
  LOT_JSON_CUSTOM_PRICE1,
  LOT_JSON_CUSTOM_PRICE2,
  LOT_JSON_CUSTOM_PROFITABILITY,
  LOT_JSON_CUSTOM_RENT,
  LOT_JSON_DELIVERY,
  LOT_JSON_EXPIRATION_DATE,
  LOT_JSON_EXPOSURE,
  LOT_JSON_FLOOR,
  LOT_JSON_HAS_PARKING,
  LOT_JSON_HAS_PLAN,
  LOT_JSON_KIND,
  LOT_JSON_KIND_TID,
  LOT_JSON_KITCHEN_PRICE,
  LOT_JSON_KITCHEN_TYPE,
  LOT_JSON_NID,
  LOT_JSON_NUMBER,
  LOT_JSON_OTHERS,
  LOT_JSON_PRICE_INCLUDING_TAX,
  LOT_JSON_PRICE_REDUCED_VAT,
  LOT_JSON_PROFITABILITY,
  LOT_JSON_PROGRAM_ID,
  LOT_JSON_PROGRAM_REF,
  LOT_JSON_PROMOTIONS,
  LOT_JSON_RENT_FURNISHED,
  LOT_JSON_RENT_TTC,
  LOT_JSON_SALES_INTERN,
  LOT_JSON_SALES_VI2P,
  LOT_JSON_STATUS,
  LOT_JSON_SURFACE,
  LOT_JSON_SURFACE_ANNEX,
  LOT_JSON_TAX,
  LOT_JSON_TYPE,
  LOT_STATUS_FREE,
  LOT_STATUS_OPTIONED,
  LOT_STATUS_PRE_BOOKED,
  LOT_CELL_TYPE_PERCENTAGE as PERCENTAGE,
  LOT_CELL_TYPE_PLAN as PLAN,
  LOT_CELL_TYPE_PRICE as PRICE,
  LOT_CELL_TYPE_PROGRAM as PROGRAM,
  LOT_CELL_TYPE_PROMO as PROMO,
  LOT_CELL_TYPE_RENT as RENT,
  LOT_CELL_TYPE_STATUS as STATUS,
  LOT_CELL_TYPE_SURFACE as SURFACE,
  LOT_CELL_TYPE_SURFACE_ANNEX as SURFACE_ANNEX,
  LOT_CELL_TYPE_TAX as TAX,
  LOT_CELL_TYPE_VALUE as VALUE,
} from 'settings/lots';
import {
  KINDS_ORDER,
  SEARCH_CRITERIA_CONTROLLED_PRICE,
  SEARCH_CRITERIA_HONOBOOST,
  SORT_LOT_BY_DELIVERY,
  SORT_LOT_BY_EXPOSURE,
  SORT_LOT_BY_FLOOR,
  SORT_LOT_BY_NUMBER,
  SORT_LOT_BY_PARKING,
  SORT_LOT_BY_PLAN,
  SORT_LOT_BY_PRICE_IMMO_HT,
  SORT_LOT_BY_PRICE_INCLUDING_TAX,
  SORT_LOT_BY_PRICE_MOB_HT,
  SORT_LOT_BY_PRICE_REDUCED_VAT,
  SORT_LOT_BY_PROFITABILITY,
  SORT_LOT_BY_PROFITABILITY_CUSTOM,
  SORT_LOT_BY_RENT_CUSTOM,
  SORT_LOT_BY_STATUS,
  SORT_LOT_BY_SURFACE,
  SORT_LOT_BY_SURFACE_ANNEX,
  SORT_ORDER_ASC,
} from 'settings/search';
import {
  TAX_TYPE_BRS,
  TAX_TYPE_DEFICIT,
  TAX_TYPE_DEMEMBREMENT,
  TAX_TYPE_LMNP,
  TAX_TYPE_LMNP_MANAGED,
  TAX_TYPE_MALRAUX,
  TAX_TYPE_NONE,
  TAX_TYPE_PLS,
} from 'settings/taxes';

import type { Activity } from 'api/vi3pAPI/apiTypes/ActivityType';
import type {
  HeadingType,
  LotExport,
  LotJson,
  LotSortBy,
  LotSortOrder,
  LotTypeV2,
} from 'api/viOffresAPI/apiTypes/LotType';
import { isLotList, isLotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';
import type { ProgramListType } from 'api/viOffresAPI/apiTypes/Program';
import type { StatusLabel } from 'api/viOffresAPI/apiTypes/Statuses';
import type { TaxType, TaxesById } from 'api/viOffresAPI/apiTypes/Taxonomies';

import { floorFormatter } from './formatter';
import { identity } from './functional';
import { normalizeTax } from './taxes';
import { FLOORS } from '../settings';

const formatLot = memoize(
  (lot: LotExport, program: ProgramListType): LotJson | undefined => {
    if (lot.length < LOT_ARRAY_LENGTH) {
      return undefined;
    }
    const deliveryDate = lot[LOT_JSON_DELIVERY];
    const expirationDate = lot[LOT_JSON_EXPIRATION_DATE];
    return {
      nid: lot[LOT_JSON_NID],
      programId: lot[LOT_JSON_PROGRAM_ID],
      tax: lot[LOT_JSON_TAX],
      type: lot[LOT_JSON_TYPE],
      number: lot[LOT_JSON_NUMBER],
      kindTid: lot[LOT_JSON_KIND_TID],
      kind: lot[LOT_JSON_KIND],
      floor: floorFormatter(lot[LOT_JSON_FLOOR]),
      exposure: lot[LOT_JSON_EXPOSURE],
      surface: Number(lot[LOT_JSON_SURFACE]),
      surfaceAnnex: Number(lot[LOT_JSON_SURFACE_ANNEX]),
      hasParking: lot[LOT_JSON_HAS_PARKING] ? 'Oui' : 'Non',
      priceReducedVat: Number(lot[LOT_JSON_PRICE_REDUCED_VAT]),
      priceIncludingTax: Number(lot[LOT_JSON_PRICE_INCLUDING_TAX]),
      rentTTC: Number(lot[LOT_JSON_RENT_TTC]),
      rentFurnished: Number(lot[LOT_JSON_RENT_FURNISHED]),
      profitability: lot[LOT_JSON_CUSTOM_PROFITABILITY]
        ? Number(lot[LOT_JSON_CUSTOM_PROFITABILITY])
        : Number(lot[LOT_JSON_PROFITABILITY]),
      delivery: deliveryDate ? new Date(deliveryDate) : null,
      status: lot[LOT_JSON_STATUS],
      expirationDate: expirationDate ? new Date(expirationDate) : null,
      customPrice1: Number(lot[LOT_JSON_CUSTOM_PRICE1]),
      customPrice2: Number(lot[LOT_JSON_CUSTOM_PRICE2]),
      customRent: Number(lot[LOT_JSON_CUSTOM_RENT]),
      customProfitability: Number(lot[LOT_JSON_CUSTOM_PROFITABILITY]),
      program,
      attributions: lot[LOT_JSON_ATTRIBUTION],
      annexes: lot[LOT_JSON_ANNEXES],
      others: lot[LOT_JSON_OTHERS],
      plan: lot[LOT_JSON_HAS_PLAN] || '',
      ref: lot[LOT_JSON_PROGRAM_REF],
      kitchen: {
        price: lot[LOT_JSON_KITCHEN_PRICE],
        type: lot[LOT_JSON_KITCHEN_TYPE],
      },
      venteInterne: lot[LOT_JSON_SALES_INTERN],
      ventePartenaireVi2p: lot[LOT_JSON_SALES_VI2P],
      promotions: lot[LOT_JSON_PROMOTIONS],
    };
  },
  {
    length: 2,
    normalizer: ([lot]) => `${lot[LOT_JSON_PROGRAM_REF]}-${lot[LOT_JSON_NUMBER]}`,
  }
);
export { formatLot };

export function formatLots(
  data: LotExport[] | undefined,
  programsById: Record<string, ProgramListType | undefined>
) {
  if (!programsById || !data) {
    return [];
  }
  return data.reduce((arr, lot) => {
    const program = programsById[lot[LOT_JSON_PROGRAM_ID]];
    if (!lot || !program) {
      return arr;
    }
    const formattedLot = formatLot(lot, program);
    return formattedLot ? [...arr, formattedLot] : arr;
  }, []);
}

export function isLotOptionnedByMe(
  myOptions: Activity[] | undefined,
  programRef: string,
  lotNumber: string
) {
  return (
    myOptions?.some(
      option =>
        option.field_numerolot_ac === lotNumber && option.field_referenceprogramme_ac === programRef
    ) ?? false
  );
}

export function isLotPreBookedByMe(
  myBookings: Activity[] | undefined,
  programRef: string,
  lotNumber: string
) {
  return (
    myBookings?.some(
      prebook =>
        prebook.field_numerolot_ac === lotNumber &&
        prebook.field_referenceprogramme_ac === programRef
    ) ?? false
  );
}

export function getLotActionButtons(
  lotStatus: StatusLabel | undefined,
  isMyActivity: boolean,
  readonly?: any
) {
  if (readonly) {
    return {
      showCancelOption: false,
      showCancelPreBook: false,
      showMakeOption: false,
      showMakePrebook: false,
    };
  }
  return {
    showCancelOption: lotStatus === LOT_STATUS_OPTIONED && isMyActivity,
    showCancelPreBook: lotStatus === LOT_STATUS_PRE_BOOKED && isMyActivity,
    showMakeOption: lotStatus === LOT_STATUS_FREE,
    showMakePrebook:
      lotStatus === LOT_STATUS_FREE || (lotStatus === LOT_STATUS_OPTIONED && isMyActivity),
  };
}

export function getLotHeadingPrice1(
  tax: TaxType | undefined,
  vat = true,
  programPage?: boolean
): HeadingType | false {
  switch (tax) {
    case TAX_TYPE_LMNP_MANAGED:
      return {
        field: 'customPrice1',
        title: 'Prix immo. HT',
        type: PRICE,
        id: 'price1',
        sort: 'customPrice1',
      };
    case TAX_TYPE_DEMEMBREMENT:
      return {
        field: 'customPrice1',
        title: 'Prix commercial TTC',
        type: PRICE,
        id: 'price1',
        sort: 'customPrice1',
      };
    case TAX_TYPE_DEFICIT:
      return {
        field: 'customPrice1',
        sub: 'TVA Normale',
        title: 'Prix immo.',
        type: PRICE,
        id: 'price2',
        sort: 'customPrice1',
      };
    case TAX_TYPE_LMNP: // LMNP is voluntarily handled the same way as default
    case TAX_TYPE_NONE:
      return {
        field: 'priceIncludingTax',
        sub: 'TVA Normale',
        title: 'Prix immo.',
        type: PRICE,
        id: 'price1',
        sort: 'priceIncludingTax',
      };
    case TAX_TYPE_MALRAUX:
      return {
        field: 'priceReducedVat',
        title: 'Prix total TTC',
        type: PRICE,
        id: 'price1',
        sort: 'priceReducedVat',
      };
    default:
      if (vat) {
        return {
          field: 'priceIncludingTax',
          title: 'Prix immo.',
          sub: programPage ? 'TVA Normale' : 'TTC',
          type: PRICE,
          id: 'price1',
          sort: 'priceIncludingTax',
        };
      }
      return false;
  }
}

export function getLotHeadingPrice2(tax: TaxType | undefined): HeadingType | false {
  switch (tax) {
    case TAX_TYPE_LMNP_MANAGED:
      return {
        field: 'customPrice2',
        title: 'Prix mob. HT',
        type: PRICE,
        id: 'price2',
        sort: 'customPrice2',
      };

    case TAX_TYPE_LMNP: // LMNP is voluntarily handled the same way as default
    case TAX_TYPE_NONE:
      return {
        field: 'priceReducedVat',
        sub: 'TVA Réduite',
        title: 'Prix immo.',
        type: PRICE,
        id: 'price2',
        sort: 'priceReducedVat',
      };
    case TAX_TYPE_MALRAUX:
    case TAX_TYPE_DEMEMBREMENT:
    case TAX_TYPE_DEFICIT:
      return false;
    default:
      return {
        field: 'priceReducedVat',
        sub: 'TVA Réduite',
        title: 'Prix immo.',
        type: PRICE,
        id: 'price2',
        sort: 'priceReducedVat',
      };
  }
}

export function getLotHeadingRentProfitability(tax: TaxType | undefined): HeadingType | false {
  switch (tax) {
    case TAX_TYPE_DEMEMBREMENT:
      return false;

    case TAX_TYPE_LMNP_MANAGED:
      return {
        field: ['customRent', 'profitability'],
        title: ['Loyer annuel HT', 'Renta. annuelle HT'],
        type: [RENT, PERCENTAGE],
        id: 'rentProfit',
        sort: 'customRent',
      };

    case TAX_TYPE_LMNP:
      return {
        field: 'customRent',
        title: 'Loyer marché',
        type: RENT,
        id: 'rentProfit',
        sort: 'customRent',
      };

    case TAX_TYPE_DEFICIT:
    case TAX_TYPE_NONE:
    case TAX_TYPE_MALRAUX:
      return {
        field: ['rentTTC'],
        sub: 'potentiel',
        title: ['Loyer marché'],
        type: [RENT, PERCENTAGE],
        id: 'rentProfit',
        sort: 'rentTTC',
      };

    default:
      return {
        field: ['rentTTC', 'profitability'],
        title: ['Loyer', 'Rentabilité'],
        type: [RENT, PERCENTAGE],
        id: 'rentProfit',
        sort: 'rentTTC',
      };
  }
}

export function getLotHeadings(
  mixed: boolean,
  tax: TaxType | undefined,
  vat: boolean,
  programPage: boolean,
  viewportMode: 'mobile' | 'tablet' | 'desktop',
  showTax: boolean,
  furnish = false
) {
  const isLmnpManaged = tax === TAX_TYPE_LMNP_MANAGED;
  const isLmnpSimple = tax === TAX_TYPE_LMNP_MANAGED;
  const hasParking: HeadingType = {
    field: 'hasParking',
    title: 'Parking',
    type: VALUE,
    id: 'hasParking',
    sub: '(annexe)',
    sort: 'hasParking',
  };
  const unfilteredHeadings: (HeadingType | false)[] = [
    { title: '', id: 'promo', type: PROMO, separator: 'none' },
    { field: CHECKBOX, title: '', type: CHECKBOX, id: CHECKBOX, separator: 'none' },
    !mixed &&
      showTax && {
        field: 'tax',
        title: 'Fiscalité',
        type: TAX,
        id: 'tax',
        separator: 'none',
        sort: 'tax',
      },
    !programPage && {
      field: 'program',
      title: 'Programmes / Ville',
      type: PROGRAM,
      id: 'program',
      sort: 'program',
    },
    isLmnpManaged && { field: 'type', title: 'Nature', type: VALUE, id: 'type', sort: 'type' },
    { field: 'number', title: 'Lot', type: LOT, id: 'number', sort: 'number' },
    {
      field: 'kind',
      title: 'Typo',
      type: viewportMode === 'desktop' ? KIND_WITH_PLAN : VALUE,
      id: 'kind',
      sort: 'kind',
    },
    !mixed && { field: 'floor', title: 'Étage', type: VALUE, id: 'floor', sort: 'floor' },
    programPage && {
      field: 'exposure',
      title: 'Exposition',
      type: VALUE,
      id: 'exposure',
      sort: 'exposure',
    },
    !mixed && viewportMode === 'tablet' && hasParking,
    {
      field: 'surface',
      title: 'Surf. hab.',
      type: SURFACE,
      id: 'surface',
      sub: '(m²)',
      sort: 'surface',
    },
    !mixed && {
      field: 'surfaceAnnex',
      title: 'Surf.Anx',
      type: SURFACE_ANNEX,
      id: 'surfaceAnnex',
      sub: '(m²)',
      sort: 'surfaceAnnex',
    },
    !mixed && viewportMode !== 'tablet' && hasParking,
    mixed && getLotHeadingPrice1(tax, vat),
    !mixed && !isLmnpSimple && !isLmnpManaged && getLotHeadingPrice2(tax),
    !mixed && !isLmnpSimple && !isLmnpManaged && getLotHeadingPrice1(tax, vat, programPage),
    !mixed && (isLmnpManaged || isLmnpSimple) && getLotHeadingPrice1(tax, vat),
    !mixed && (isLmnpManaged || isLmnpSimple) && getLotHeadingPrice2(tax),
    getLotHeadingRentProfitability(tax),
    furnish && {
      field: 'rentFurnished',
      title: 'Loyer Meublé',
      type: RENT,
      id: 'rentFurnished',
      sort: 'rentFurnished',
    },
    !programPage &&
      !mixed && {
        field: 'delivery',
        title: 'Livraison',
        type: DATE,
        id: 'delivery',
        sort: 'delivery',
      },
    { field: 'status', title: 'Statut', type: STATUS, id: 'status', sort: 'status' },
    !mixed && viewportMode !== 'desktop' && { title: 'Plan', type: PLAN, id: 'plan' },
    { title: '', id: 'action', type: ACTION, separator: 'none' },
  ];
  return unfilteredHeadings.filter(identity) as HeadingType[];
}

function getHeadingsType(reduced: boolean, tax: TaxType | undefined) {
  switch (tax) {
    case TAX_TYPE_LMNP:
    case TAX_TYPE_LMNP_MANAGED:
      return reduced ? HEADINGS_TYPE_LMNP_REDUCED_PANEL : HEADINGS_TYPE_LMNP;

    case TAX_TYPE_DEMEMBREMENT:
      return reduced ? HEADINGS_TYPE_DEMEMBREMENT_REDUCED_PANEL : HEADINGS_TYPE_DEMEMBREMENT;

    case TAX_TYPE_NONE:
      return reduced ? HEADINGS_TYPE_HORS_DISPO_REDUCED_PANEL : HEADINGS_TYPE_HORS_DISPO;

    case TAX_TYPE_BRS:
    case TAX_TYPE_PLS:
      return reduced ? HEADINGS_TYPE_DEFAULT_REDUCED_PANEL : HEADINGS_TYPE_DEFAULT;

    default:
      return reduced ? HEADINGS_TYPE_REDUCED_PANEL : null;
  }
}

export function getHeadingsWidth(
  reduced: Parameters<typeof getHeadingsType>[0],
  tax: Parameters<typeof getHeadingsType>[1]
): Record<string, number> {
  const type = getHeadingsType(reduced, tax);
  switch (type) {
    case HEADINGS_TYPE_DEMEMBREMENT:
      return {
        checkbox: 3.5,
        program: 19.8,
        number: 7.5,
        kind: 4.4,
        floor: 4.8,
        surface: 6.6,
        surfaceAnnex: 5.5,
        hasParking: 6,
        price1: 11.7,
        rentProfit: 5.8,
        rentFurnished: 5.8,
        delivery: 6,
        status: 9.1,
        action: 3.5,
      };

    case HEADINGS_TYPE_DEMEMBREMENT_REDUCED_PANEL:
      return {
        checkbox: 5,
        program: 26.7,
        number: 11,
        kind: 6.6,
        surface: 10,
        price1: 17.7,
        rentProfit: 5.8,
        rentFurnished: 5.8,
        status: 17,
        action: 6,
      };

    case HEADINGS_TYPE_HORS_DISPO:
      return {
        checkbox: 3.5,
        program: 15.3,
        number: 5.2,
        kind: 4,
        floor: 4.3,
        surface: 6,
        surfaceAnnex: 5,
        hasParking: 5.4,
        price2: 6.5,
        price1: 6.9,
        rentProfit: 9,
        rentFurnished: 9,
        delivery: 6,
        status: 9.1,
        action: 3.5,
      };

    case HEADINGS_TYPE_HORS_DISPO_REDUCED_PANEL:
      return {
        checkbox: 5,
        program: 29.1,
        number: 10.1,
        kind: 7.7,
        surface: 11.6,
        price1: 13.5,
        rentProfit: 10,
        rentFurnished: 10,
        status: 17,
        action: 6,
      };

    case HEADINGS_TYPE_LMNP:
      return {
        checkbox: 3.5,
        program: 10.9,
        type: 6.3,
        number: 4.6,
        kind: 3.4,
        floor: 3.6,
        surface: 5.2,
        surfaceAnnex: 5,
        hasParking: 4.7,
        price1: 7.3,
        price2: 6.2,
        rentProfit: 7,
        rentFurnished: 7,
        delivery: 6,
        status: 9.1,
        action: 3.5,
      };

    case HEADINGS_TYPE_LMNP_REDUCED_PANEL:
      return {
        checkbox: 5,
        program: 15.5,
        type: 10.8,
        number: 7.8,
        kind: 5.9,
        surface: 8.9,
        price1: 12.6,
        rentProfit: 11,
        rentFurnished: 11,
        status: 17,
        action: 6,
      };

    case HEADINGS_TYPE_DEFAULT:
      return {
        checkbox: 3.5,
        program: 14,
        number: 5.7,
        kind: 3.9,
        floor: 4.2,
        surface: 5.1,
        surfaceAnnex: 5,
        hasParking: 4.6,
        price2: 6,
        price1: 6,
        rentProfit: 5.8,
        rentFurnished: 5.8,
        delivery: 6,
        status: 10.1,
        action: 4.5,
      };

    case HEADINGS_TYPE_DEFAULT_REDUCED_PANEL:
      return {
        checkbox: 5,
        program: 21.8,
        number: 9.9,
        kind: 6.4,
        surface: 9.6,
        price1: 13.6,
        rentProfit: 10.7,
        rentFurnished: 10.7,
        status: 17,
        action: 6,
      };

    case HEADINGS_TYPE_REDUCED_PANEL:
      return {
        checkbox: 5,
        program: 21.8,
        number: 9.9,
        kind: 6.4,
        surface: 9.6,
        price1: 13.6,
        rentProfit: 10.7,
        rentFurnished: 10.7,
        status: 17,
        action: 6,
      };

    default:
      return {
        checkbox: 3.5,
        tax: 6.5,
        program: 13,
        number: 5.2,
        kind: 3.4,
        floor: 3.7,
        surface: 5.5,
        surfaceAnnex: 5,
        hasParking: 4.6,
        price2: 6,
        price1: 6,
        rentProfit: 5.6,
        rentFurnished: 5.6,
        delivery: 6,
        status: 9.1,
        action: 3.5,
      };
  }
}

export function getProgramPageHeadingsWidth(tax: string | undefined): Record<string, number> {
  switch (normalizeTax(tax)) {
    case TAX_TYPE_LMNP:
    case TAX_TYPE_LMNP_MANAGED:
      return {
        checkbox: 4,
        type: 10,
        number: 5,
        kind: 4,
        floor: 4,
        exposure: 7,
        surface: 6,
        surfaceAnnex: 5,
        hasParking: 5,
        price1: 6,
        price2: 6,
        rentProfit: 10,
        rentFurnished: 6,
        status: 10,
        action: 4,
      };

    case TAX_TYPE_DEMEMBREMENT:
      return {
        checkbox: 4,
        number: 10,
        kind: 6,
        floor: 7,
        exposure: 10,
        surface: 9,
        surfaceAnnex: 13,
        hasParking: 8,
        price1: 15,
        status: 10,
        action: 4,
      };

    case TAX_TYPE_NONE:
      return {
        checkbox: 4,
        number: 6,
        kind: 5,
        floor: 5,
        exposure: 8,
        surface: 8,
        surfaceAnnex: 10,
        hasParking: 6,
        price2: 8,
        price1: 8,
        rentProfit: 8,
        rentFurnished: 8,
        status: 10,
        action: 4,
      };

    default:
      return {
        checkbox: 4,
        tax: 9,
        number: 6,
        kind: 4,
        floor: 5,
        exposure: 7,
        surface: 6,
        surfaceAnnex: 6,
        hasParking: 5,
        price2: 8,
        price1: 8,
        rentProfit: 8,
        rentFurnished: 8,
        status: 10,
        action: 4,
      };
  }
}

export function getLotPrice(lot: LotExport, taxesById: TaxesById) {
  let price = lot[LOT_JSON_PRICE_INCLUDING_TAX] || lot[LOT_JSON_PRICE_REDUCED_VAT] || null;
  if ([TAX_TYPE_LMNP, TAX_TYPE_LMNP_MANAGED].includes(taxesById[lot[LOT_JSON_TAX]])) {
    // For Lots with LMNP fiscalities, use unfurnished price instead of normal price
    price = lot[LOT_JSON_CUSTOM_PRICE1];
  }

  if (price === null) {
    return null;
  }
  return Number(price);
}

export function lotHasKitchen(lot: LotJson | LotTypeV2) {
  if (isLotTypeV2(lot)) {
    return lot.packs.cuisineEquipee.type && lot.packs.cuisineEquipee.type !== KITCHEN_TYPE_NONE;
  }
  if (isLotList(lot)) {
    return lot.kitchen.type && lot.kitchen.type !== KITCHEN_TYPE_NONE;
  }
  return false;
}

export function lotIsFNO(lot: LotJson | LotTypeV2) {
  if (isLotTypeV2(lot)) {
    return lot.aidesVente.fraisNotaireOfferts.ventePartenaireVI2P;
  }
  if (isLotList(lot)) {
    return lot.ventePartenaireVi2p;
  }
  return false;
}

export function lotIsHonoboost(lot: LotTypeV2 | LotJson) {
  if (isLotTypeV2(lot)) {
    return lot.promotions.some(promo => promo.honoboost);
  }
  if (isLotList(lot)) {
    return lot.others.includes(SEARCH_CRITERIA_HONOBOOST);
  }
  return false;
}

export function lotIsControlledPrice(lot: LotTypeV2 | LotJson) {
  if (isLotTypeV2(lot)) {
    return lot.dispositifsSpecifiques.prixMaitrises;
  }
  if (isLotList(lot)) {
    return lot.others.includes(SEARCH_CRITERIA_CONTROLLED_PRICE);
  }
  return false;
}

export function lotIsFurnishEligible(lot: LotTypeV2 | LotJson | LotExport) {
  if (Array.isArray(lot)) {
    return !!Number(lot[LOT_JSON_RENT_FURNISHED]);
  }
  if (isLotTypeV2(lot)) {
    return parseFloat(lot.investissementLocatif.loyerBailMensuel ?? '') > 0;
  }
  if (isLotList(lot)) {
    return !!lot.rentFurnished;
  }
  return false;
}

export function sortLots(
  lots: LotJson[],
  sortBy: LotSortBy,
  sortOrder: LotSortOrder,
  taxById?: TaxesById
) {
  if (sortBy === ID_KIND) {
    const sortedDatas = [...lots].sort((a, b) => {
      return KINDS_ORDER.indexOf(a.kind) - KINDS_ORDER.indexOf(b.kind);
    });

    return sortOrder === SORT_ORDER_ASC ? sortedDatas : sortedDatas.reverse();
  }

  if (sortBy === ID_TAX && taxById) {
    return [...lots].sort((a, b) => {
      const sign = sortOrder === SORT_ORDER_ASC ? 1 : -1;

      if (a.tax === null || !taxById[a.tax]) {
        return sign * -1;
      }

      if (b.tax === null || !taxById[b.tax]) {
        return sign * 1;
      }

      return sign * taxById[a.tax].localeCompare(taxById[b.tax]);
    });
  }

  return [...lots].sort((...lotsToCompare) => {
    const order = Number(sortOrder === SORT_ORDER_ASC) * 2 - 1;
    const [value1, value2] = lotsToCompare.map(lot => {
      switch (sortBy) {
        case SORT_LOT_BY_SURFACE_ANNEX:
          return lot.surfaceAnnex;

        case SORT_LOT_BY_PRICE_IMMO_HT:
          return lot.customPrice1;

        case SORT_LOT_BY_PRICE_INCLUDING_TAX:
          return lot.priceIncludingTax;

        case SORT_LOT_BY_PRICE_MOB_HT:
          return lot.customPrice2;

        case SORT_LOT_BY_RENT_CUSTOM:
          return lot.customRent;

        case SORT_LOT_BY_PRICE_REDUCED_VAT:
          return lot.priceReducedVat;

        case SORT_LOT_BY_DELIVERY:
          return lot.delivery;

        case SORT_LOT_BY_EXPOSURE:
          return lot.exposure;

        case SORT_LOT_BY_FLOOR:
          return Object.values(FLOORS).indexOf(lot.floor as typeof FLOORS[keyof typeof FLOORS]);

        case SORT_LOT_BY_NUMBER:
          return lot.number;

        case SORT_LOT_BY_PARKING:
          return lot.hasParking;

        case SORT_LOT_BY_PLAN:
          return lot.plan ? '1' : '2';

        case SORT_LOT_BY_PROFITABILITY:
          return lot.profitability;

        case SORT_LOT_BY_PROFITABILITY_CUSTOM:
          return lot.customProfitability;

        case SORT_LOT_BY_STATUS:
          return lot.status;

        case SORT_LOT_BY_SURFACE:
          return lot.surface;

        default:
          return 0;
      }
    });

    if (value1 === value2) {
      return 0;
    }
    return order * (Number((value1 as number) > (value2 as number)) * 2 - 1);
  });
}
